import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagramSquare, faLine, faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faPhoneSquareAlt, faFax, faHome } from "@fortawesome/free-solid-svg-icons"
import { motion, Variants } from 'framer-motion'
import InnerLinkListItem from './atoms/innerLinkListItem'
import ExternalLinkIcon from './atoms/externalLinkIcon'
import SimpleExternalLink from './atoms/simpleExternalLink'

const Footer = () => {

    const fadeIn: Variants = {
        offscreen: {
          x: 0,
          opacity: 0,
        },
        onscreen: {
          x: 0,
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0,
            duration: 0.8,
          },
        }
      }
    
    return (
        <motion.div 
            initial='offscreen'
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.3 }}
            className='pt-4 my-5'
            >
            <hr className='m-2 max-w-screen-lg mx-auto'/>
            <motion.div variants={fadeIn}>
                <h1 className='text-center text-lg text-gray-500 m-4 tracking-widest md:text-3xl'>SNS</h1>
                <div className='w-full my-4'>
                    <div className='flex justify-around max-w-screen-lg mx-auto my-6'>
                        <ExternalLinkIcon to={`https://twitter.com/Mitsuwaoff`} text={`Twitter`} icon={faTwitter} color={`text-blue-400`} />
                        <ExternalLinkIcon to={`https://www.instagram.com/mitsuwa_off`} text={`Instagram`} icon={faInstagramSquare} color={`text-pink-500`} />
                        <ExternalLinkIcon to={`https://page.line.me/733jjyfw?openQrModal=true`} text={`Line`} icon={faLine} color={`text-green-500`} />
                        <ExternalLinkIcon to={`https://www.facebook.com/mitsuwaoffice`} text={`Facebook`} icon={faFacebook} color={`text-blue-500`} />
                    </div>
                </div>
                <div className="text-white body-font bg-indigo-900">
                    <div className="container pt-8 pb-6 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                        <div className="w-full md:w-1/2 lg:w-1/3 md:flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
                            <div className='className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"'>
                                <div className='mx-auto'>
                                    <p className="mt-2 text-lg">司法書士法人みつわ合同事務所</p>
                                    <p className="mt-2 text-md">〒541-0051</p>
                                    <p className="mt-2 text-md">大阪市中央区備後町4丁目1番3号</p>
                                    <p className="mt-2 text-md">御堂筋三井ビルディングB1F</p>
                                    <p className="mt-2 text-md">
                                        <FontAwesomeIcon icon={faPhoneSquareAlt} className='text-white mr-2'/>
                                        TEL: 06-6222-7744
                                    </p>
                                    <p className="mt-2 text-md">
                                        <FontAwesomeIcon icon={faFax} className='text-white mr-2'/>
                                        FAX: 06-6222-7745
                                    </p>
                                    <p className="mt-2 text-md">
                                        <FontAwesomeIcon icon={faHome} className='text-white mr-2'/>
                                        <SimpleExternalLink to={`https://www.mitsuwa-off.jp`} text={`URL: https://www.mitsuwa-off.jp`} />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow flex items-center flex-wrap md:text-left text-center order-first">
                            <div className="w-full px-4">
                                <h2 className="title-font font-mediumtracking-widest text-center text-lg mb-3">サイトマップ</h2>
                                <div className='flex flex-col justify-evenly md:flex-row'>
                                    <nav className="list-none md:mb-10">
                                        <ul>
                                            <InnerLinkListItem to={`/`} text={`TOP`}/>
                                            <InnerLinkListItem to={`/difficult`} text={`相続困難事例`}/>
                                            <InnerLinkListItem to={`/method`} text={`相続登記の進め方`}/>
                                            <InnerLinkListItem to={`/cost`} text={`相続登記費用`}/>
                                        </ul>
                                    </nav>
                                    <nav className="list-none md:mb-10">
                                        <ul>
                                            <InnerLinkListItem to={`/about`} text={`事務所概要`}/>
                                            <InnerLinkListItem to={`/contact`} text={`お問い合わせ`}/>
                                            
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100">
                        <div className="container mx-auto py-4 px-5">
                            <p className="text-gray-500 text-sm text-center">
                                <SimpleExternalLink to={`https://www.mitsuwa-off.jp/`} text={`© 2022- 司法書士法人みつわ合同事務所`} />
                            </p>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>       
    )
}

export default Footer