import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet'
import React from 'react';

const SEO = ({title, description, }) => {
    const { site } = useStaticQuery(query)
    const defaultTitle = site.siteMetadata.title

    return (
        <Helmet 
            htmlAttributes={{
                lang: 'ja',
            }}
            title={title !== "" ? `${title} | ${defaultTitle}` : defaultTitle}
            meta={[
                {
                    name: `description`,
                    content: `${description}` || `${site.siteMetadata.description}`,
                },
                {
                    property: `og:site_name`,
                    content: `${defaultTitle}`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: `${description}` || `${site.siteMetadata.description}`,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    property: `og:locale`,
                    content: site.siteMetadata.locale,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: `${description}` || `${site.siteMetadata.description}`,
                },
                {
                    name: `fa:app_id`,
                    content: site.siteMetadata.fbappid,
                },
                {
                    name: `og:image`,
                    content: `${site.siteMetadata.siteUrl}/images/logo.png`,
                },
                {
                    name: `thumbnail`,
                    content: `${site.siteMetadata.siteUrl}/images/logo.png`,
                },

            ]}
        />
        
    );
};

export default SEO

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                siteUrl
                author
                locale
                fbappid
            }
        }
    }

`

