import React from 'react';

const useLinks = () => {
    const links = [
        { name: 'Top', to: "/", id: 1 },
        { name: '相続困難事例', to: "/difficult", id: 2 },
        { name: '相続登記の進め方', to: "/method", id: 3 },
        { name: '相続登記費用', to: "/cost", id: 4 },
        { name: '事務所概要', to: "/about", id: 5 },
        { name: 'お問い合わせ', to: "/contact", id: 6 },
    ]
    
    return {
        links
    };
};

export default useLinks;
