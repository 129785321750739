import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

const ExternalLinkIcon = ({to, text, icon, color}) => {
  return (
    <motion.a whileHover={{scale: 1.5, opacity: 0.8}} whileTap={{scale: 1.5, opacity: 0.8}} href={to}  target='_blank' rel='noopener noreferrer'>
        <div className='text-center'>    
            <FontAwesomeIcon icon={icon} className={`${color} text-3xl md:text-4xl`} />
            <p className='text-sm text-gray-600'>{text}</p>
        </div>
    </motion.a>
  );
};

export default ExternalLinkIcon;
