import React from 'react'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => {

    return (
        <>
            <div className='relative m-auto mt-10 lg:mt-0'>
                <div className='w-full max-w-screen-md object-contain'>
                    <StaticImage
                        alt="heroImage"
                        src="https://images.unsplash.com/photo-1497005367839-6e852de72767?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2934&q=80"
                        sizes='100%'
                        />
                </div>
                <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-60'>
                </div>
                <motion.div animate={{x: [-400, 10, 0], opacity:[0, 1]}} transition={{ duration: 1,}} className='absolute inset-y-1/3 w-full text-center'>       
                    <h1 className='text-white text-3xl font-extrabold tracking-widest md:text-5xl'>相続登記特設ページ</h1>
                    <h2 className='text-white my-8 text-md md:text-2xl'>令和6年4月1日から相続登記の義務化決定！</h2>     
                </motion.div>
            </div>
        </>
    )
}

export default Header