import { motion } from 'framer-motion';
import React from 'react';

const SimpleExternalLink = ({to, text}) => {
    return (  
        <motion.a whileHover={{ opacity: 0.8 }} href={to} target='_blank' rel='noopener noreferrer' className='hover:text-red-500 hover:bg-pink-300'>{text}</motion.a>
    );
};

export default SimpleExternalLink;
