import useLinks from '@/hooks/useLinks';
import React from 'react';
import InnerLinkListItem from '../atoms/innerLinkListItem';

const LargeNavigationMenu = () => {
    const { links } = useLinks()
    return (
        <div className='hidden lg:flex-auto lg:block'>
            <ul className='flex text-white p-2 justify-around text-sm'>
                {links.map(({name, to, id}) => (
                    <InnerLinkListItem text={name} to={to} key={id} />
                ))}

            </ul>
        </div>
    );
};

export default LargeNavigationMenu;
