import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useCycle } from 'framer-motion'
import NavigationToggleMenu from './blocks/navigationToggleMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import LargeNavigationMenu from './blocks/largeNavigationMenu'

const Navbar = () => {
    const [open, cycleOpen] = useCycle(false, true)

    return (
        <div className='fixed top-0 left-0 right-0 h-auto z-30 lg:static'>
            <nav className='bg-indigo-900 flex justify-between h-14 relative'>
                <div className='flex'>
                    <div className='flex-none w-10 px-1 py-3 md:w-12 md:p-2 md:py-3 lg:w-14'>
                        <Link to='/'>
                            <StaticImage 
                                alt="logo"
                                src="../images/logo.png"
                                sizes='100%'    
                            />
                        </Link>
                    </div>
                    <div className='shrink'>
                        <h1 className='text-white text-sm px-1 pt-4 md:text-lg lg:text-lg lg:p-3'>
                            <Link to='/'>司法書士法人みつわ合同事務所</Link>
                        </h1>
                    </div>
                </div> 
                <div className='z-50 px-2 py-4 pr-4 md:p-2 md:pr-4 md:py-5 lg:hidden'>
                    <button aria-label="Menu" onClick={() => cycleOpen()}>{open ? (
                            <FontAwesomeIcon icon={faTimes} className='text-white text-lg' />
                        ) : (
                            <FontAwesomeIcon icon={faBars} className='text-white text-lg' />
                        )}
                    </button>
                </div>
                <LargeNavigationMenu />
            </nav>
            <NavigationToggleMenu isOpen={open} cycleOpen={cycleOpen} />
        </div>
    )
}

export default Navbar