import useLinks from '@/hooks/useLinks';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';


const itemVariants = {
    closed: {
        opacity: 0
    },
    open: {
        opacity: 1
    }
}

const sideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1,
        }
    },
    open: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
}


const NavigationToggleMenu = ({isOpen, cycleOpen}) => {
    const { links } = useLinks()
    
    return (
        <div className='absolute z-40 top-0 right-0 h-screen' onClick={() => cycleOpen()}>
            <AnimatePresence>
                {isOpen && (
                    <motion.div 
                        initial={{ width: 0 }} 
                        animate={{ width: 300 }} 
                        exit={{ width: 0, transition: { delay: 0.8, duration: 0.3}}}
                        className='p-10 bg-indigo-900 bg-opacity-90 h-screen'
                    >
                        <motion.div
                            initial='closed'
                            animate='open'
                            exit='closed'
                            variants={sideVariants}
                            className=''
                        >
                            <ul className='flex flex-col text-white font-bold'>
                                { links.map(({ name, to, id }) => (
                                    <motion.li key={id} whileHover={{ scale: 0.9, opacity: 0.8 }} whileTap={{ scale: 0.9, opacity: 0.8 }} variants={itemVariants} className='m-2 tracking-widest text-md hover:text-red-500'>
                                        <Link to={to}>{ name }</Link>
                                    </motion.li>
                                ))}

                            </ul>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NavigationToggleMenu;
