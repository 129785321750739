import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';

const InnerLinkListItem = ({to, text}) => {
  return (
    <motion.li whileHover={{ scale: 0.9, opacity: 0.8 }} whileTap={{scale: 0.9, opacity: 0.8 }} className='m-2 tracking-widest text-md hover:text-red-500'>
        <Link to={to}>{text}</Link>
    </motion.li>
  );
};

export default InnerLinkListItem;
